import request from "@/utils/request";

// 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/customer/getCustomerList",
    method: "get",
    params: query
  });
}

// 删除客户
export function delList(ids) {
  return request({
    url: "/crm/customer/deleteCustomer/" + ids,
    method: "delete"
  });
}

// 查询跟进列表
export function fetchCustomerFollowList(query) {
  return request({
    url: "/crm/customer/getFollowList",
    method: "get",
    params: query
  });
}

// 获取数量
export function fetchCount() {
  return request({
    url: "/crm/customer/getNumber",
    method: "get"
  });
}

// 添加团队成员
export function submitMembers(id, userIds) {
  return request({
    url: `/crm/customer/addRelatedTeam/${id}/${userIds}`,
    method: "post"
  });
}

// 添加客户
export function addCustomer(data) {
  return request({
    url: "/crm/customer/addCustomer",
    method: "post",
    data
  });
}

// 放入公海
export function putPublic(ids) {
  return request({
    url: "/crm/customer/moveInPblic/" + ids,
    method: "PUT"
  });
}

// 编辑客户
export function editCustomer(data) {
  return request({
    url: "/crm/customer/updateCustomer",
    method: "PUT",
    data
  });
}

// 根据客户Id获取信息
export function getDetailByCustomerId(params) {
  return request({
    url: "/crm/customer/getId",
    method: "get",
    params
  });
}

// 根据用户id查询用户信息
export function fetchUserInfo(params) {
  return request({
    url: "/system/user/baseMsg/" + params,
    method: "get"
  });
}

// 新建跟进
export function addFollowCustomer(data) {
  return request({
    url: "/crm/customer/addFollow",
    method: "POST",
    data
  });
}

// 编辑跟进
export function editFollowCustomer(data) {
  return request({
    url: "/crm/customer/updateFollow",
    method: "PUT",
    data
  });
}

// 删除跟进
export function delFollowList(data) {
  return request({
    url: "/crm/customer/deleteFollow",
    method: "delete",
    data
  });
}

// 转移客户
export function fetchTransferCus(data,ids) {
  return request({
    url: `/crm/customer/transferCustomer/`+ids,
    method: "put",
    data
  });
}

// 添加下次联系
export function nextContact(data,ids) {
  return request({
    url: `/crm/customer/nextContact/`+ids,
    method: "put",
    data
  });
}


// 取消下次联系
export function cancelContact(id) {
  return request({
    url: `/crm/customer/cancelContact/${id}`,
    method: "put"
  });
}

// 移除团队成员
export function removeUser(data) {
  return request({
    url: "/crm/customer/deleteRelatedTeam",
    method: "delete",
    data
  });
}

// 新建联系人
export function addContacts(data) {
  return request({
    url: "/crm/contacts/addContacts",
    method: "POST",
    data
  });
}

// 获取联系人列表
export function fetchContactList(query) {
  return request({
    url: "/crm/currency/getContactsListByCustomerId",
    method: "get",
    params: query
  });
}

// 设为首要联系人
export function setMainContact(data) {
  return request({
    url: "/crm/contacts/setUp",
    method: "PUT",
    data
  });
}
// 客户管理，团队成员,
export function fetchTeamList(query) {
  return request({
    url: "/crm/customer/listRelatedTeam",
    method: "get",
    params: query
  });
}

// 根据客户id获取商机
export function fetchBusinessListByCustomrId(params) {
  return request({
    url: "/crm/currency/getCostListByCustomerId",
    method: "get",
    params
  });
}

// 根据客户id获取本人及下属发票列表
export function fetchInvoiceListByCustomerId(params) {
  return request({
    url: "/crm/currency/getInvoiceByCustomerIdToSelfSub",
    method: "get",
    params
  });
}

// 获取客户集合（所有）
export function fetchAllCustomerList(params) {
  return request({
    url: "/crm/customer/getAllList",
    method: "get",
    params
  });
}

// 获取客户集合(自己和下属)
export function getSelfSubCustomerPage(params) {
  return request({
    url: "/crm/customer/getSelfSubCustomerPage",
    method: "get",
    params
  });
}

// 领用
export function moveInNotPublic(params) {
  return request({
    url: "/crm/customer/moveInNotPublic/" + params,
    method: "put"
  });
}

//查询自定义字段
export function findField() {
  return request({
    url: "/crm/customer/findField",
    method: "get"
  });
}

//设置自定义字段
export function setField(data) {
  return request({
    url: "/crm/customer/setField/",
    method: "post",
    data
  });
}

//呼叫客户
export function makeCall(businessId,businessType) {
  return request({
    url: "/crm/customer/makeCall/"+businessId+"/"+businessType,
    method: "post"
  });
}

//查询自定义字段
export function findConfig(companyId) {
  return request({
    url: "/crm/config/findConfig/"+companyId,
    method: "get"
  });
}

//查询自定义字段
export function findCompanyNameList(params) {
  return request({
    url: "/crm/tyc/findCompanyNameList",
    method: "get",
    params
  });
}

//超过7天未跟进(当前用户)
export function statisticsUnFollow7(params) {
  return request({
    url: "/crm/work/statisticsUnFollow7",
    method: "get",
    params
  });
}

//查重
export function checkDuplicate(params) {
  return request({
    url: "/crm/customer/checkDuplicate",
    method: "get",
    params
  });
}

//现场打卡
export function sceneClock(data) {
  return request({
    url: "/crm/customer/sceneClock",
    method: "post",
    data
  });
}
